import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from "../assets/F (2).png";
import Hamburger from "hamburger-react";
import cross from "../assets/cross.png";
import Footer from "../Footer";
import Fade from 'react-reveal/Fade';
import brunch7D from "../assets/event-image/Fundherz - Brunch (1).png"

// Changement de Class Component en Function Component
function Event() {
    const [showIframe, setShowIframe] = useState(false);
    const [showIframeInvestors, setShowIframeInvestors] = useState(false);
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const financementRef = useRef(null);

    const handleTypeformClick = () => {
        setShowIframe(true);
        document.body.style.overflow = 'hidden';
    };

    const handleCloseIframe = () => {
        setShowIframe(false);
        document.body.style.overflow = 'auto';
    };

    const handleTypeformClickInvestors = () => {
        setShowIframeInvestors(true);
        document.body.style.overflow = 'hidden';
    };

    const handleCloseIframeInvestor = () => {
        setShowIframeInvestors(false);
        document.body.style.overflow = 'auto';
    };

    const scrollToFinancement = () => {
        financementRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="section-event content-header-event">
            <div className="mobile-only">
                <div className="content-menu-mobile">
                    <button className="btn" onClick={() => window.location.href = "/"}>
                        <img src={logo} className="logo" alt="pitchersales"/>
                    </button>
                    <div className={`menu-burger ${isScrolled ? 'scrolled' : ''}`}>
                        <Hamburger toggled={isMenuOpen} toggle={toggleMenu} className="menu-burger"/>
                    </div>
                </div>

                <div className={`mobile-menu ${isMenuOpen ? 'show-menu' : ''}`}>
                    <Link to="/">
                        <button className="mobile-menu-button" onClick={() => {
                            toggleMenu();
                        }}>
                            Découvrir
                        </button>
                    </Link>
                    <button className="mobile-menu-button" onClick={() => {
                        toggleMenu();
                    }}>
                        Evènements
                    </button>
                    <button className="mobile-menu-button" onClick={() => {
                        toggleMenu();
                        handleTypeformClick();
                    }}>
                        Obtenir des financements
                    </button>
                    <br/>
                </div>
            </div>

            <div className="container">
                <div className="content-header-event content-header d-flex-desktop desktop-only">
                    <div className="d-flex-desktop style-menu">
                        <button className="btn" onClick={() => window.location.href = "/"}>
                            <img src={logo} className="logo" alt="pitchersales"/>
                        </button>
                        <div className="d-flex-desktop content-link-menu content-menu-desktop">
                            <Link to="/">
                                <button
                                    id="projectHolder"
                                    className="isMenuOpen menu-li text-btn-header btn-discover"
                                >
                                    Découvrir
                                </button>
                            </Link>
                            <Link to="/event">
                                <button
                                    id="projectHolder"
                                    className="isMenuOpen menu-li text-btn-header btn-discover"
                                >
                                    Evènements
                                </button>
                            </Link>
                            <button
                                id="projectHolder"
                                className="isMenuOpen menu-li text-btn-header violet-color get-money-btn"
                                onClick={handleTypeformClick}
                            >
                                Obtenir des financements
                            </button>
                        </div>
                    </div>

                </div>
                <div className="events-container">
                    <a href="https://www.eventbrite.fr/e/billets-brunch-entre-entrepreneuses-1089100261199?aff=oddtdtcreator"
                       target="_blank" rel="noopener noreferrer">
                        <div className="event-item past-event">
                            <div className="event-date-tag">7 DÉC 2024</div>
                            <div className="event-card">
                                <img src={brunch7D} alt="Event"/>
                                <div className="overlay">Événement Passé</div>
                            </div>
                        </div>
                    </a>

                    {/*<div className="event-item">*/}
                    {/*    <div className="event-date-tag">22 DÉC 2024</div>*/}
                    {/*    <div className="event-card">*/}
                    {/*        <img src="https://via.placeholder.com/300x200" alt="Event"/>*/}
                    {/*    </div>*/}
                    {/*    <h3 className="event-title">Workshop Financement</h3>*/}
                    {/*</div>*/}

                    {/*<div className="event-item">*/}
                    {/*    <div className="event-date-tag">10 JAN 2025</div>*/}
                    {/*    <div className="event-card">*/}
                    {/*        <img src="https://via.placeholder.com/300x200" alt="Event"/>*/}
                    {/*    </div>*/}
                    {/*    <h3 className="event-title">Masterclass Sales</h3>*/}
                    {/*</div>*/}
                </div>


                {showIframe && (
                    <Fade right>
                        <div className="typeform-overlay">
                            <div className="typeform-container">
                                <iframe
                                    id="typeform-iframe"
                                    src="https://i59ic371bmw.typeform.com/to/s7B7i5Vw"
                                    style={{width: '100%', height: '100%', border: 'none'}}
                                    title="TypeForm"
                                />
                                <a className="close-button" onClick={handleCloseIframe}>
                                    <img src={cross} className="cross-form" alt="close"/>
                                </a>
                            </div>
                        </div>
                    </Fade>
                )}

                {showIframeInvestors && (
                    <Fade right>
                        <div className="typeform-overlay">
                            <div className="typeform-container">
                                <iframe
                                    id="typeform-iframe"
                                    src="https://i59ic371bmw.typeform.com/to/tZESMz40"
                                    style={{width: '100%', height: '100%', border: 'none'}}
                                    title="TypeForm"
                                />
                                <a className="close-button" onClick={handleCloseIframeInvestor}>
                                    <img src={cross} className="cross-form" alt="close"/>
                                </a>
                            </div>
                        </div>
                    </Fade>
                )}


                <Footer/>
            </div>
        </div>
    );
}

export default Event;